/* eslint-disable  */
import { useEffect, useState } from 'react';
import { setTrackingReady } from '@/redux/tracking/trackingReducer';
import { useDispatch } from 'react-redux';
import { GTM_ID } from '@/services/googleTagManager';

// hook para descargar la lib de google analytics con un retraso de 5s
export default function useGoogleTagManager() {
  const dispatch = useDispatch();
  const [loadTime, setLoadTime] = useState(null);

  // calculamos el tiempo que tardo en cargar la app
  useEffect(() => {
    const time = Date.now() - window.performance.timing.navigationStart;
    setLoadTime(time);
  }, []);

  useEffect(() => {
    // creamos variable necesaria para el tracker
    window.dataLayer = window.dataLayer || [];
    // verificamos si gtm no ha sido instanciado
    if (!window.dataLayer.find((element) => element['gtm.start']) && GTM_ID) {
      // iniciar settimeout de 5s
      const id = setTimeout(() => {
        // crear etiqueta <script> para que empiece a descargar
        // * Google Tag Manager - Global base code *
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          let f = d.getElementsByTagName(s)[0];
          let j = d.createElement(s);
          let dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', `${GTM_ID}`);

        // indicamos que google tag manager ya se cargo
        dispatch(setTrackingReady('googleTagManager'));
      }, 5000);

      return () => {
        clearTimeout(id);
      };
    }
    return () => {};
  }, [dispatch, loadTime]);
}
