// import VaypolCookies from '@/services/vaypolCookies';

import useFacebookPixel from '@/hooks/useFacebookPixel';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import usePageView from '@/hooks/usePageView';
// import Inspectlet from '../../services/inpectlet';

// componente wrapper para aplicar analytics y pixel

const EventTracking = ({ children }) => {
  if (process.env.NEXT_PUBLIC_ALLOW_ROBOTS === 'true') {
    // cargamos las lib de facebook pixel y analytics
    useGoogleAnalytics();
    useGoogleTagManager();
    useFacebookPixel();
    // Inspectlet();
    // VaypolCookies();

    // aplicamos el evento pageview
    usePageView();
  }

  return children;
};

export default EventTracking;
